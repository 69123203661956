<template>
  <v-menu
    location="end"
    class="pl-2"
    offset="15"
    :width="smAndDown ? '100%' : '260px'"
  >
    <template #activator="{ props }">
      <v-list-item
        prepend-icon="mdi-cog"
        value="setting"
        :title="$t('SETTING')"
        v-bind="props"
      />
    </template>
    <v-card width="100%" class="pb-3" elevation="5">
      <!-- TODO @Fierce-Cat: 这里只是临时设置一下 -->
      <v-list-item>
        <v-list-item-title class="text-body-1 font-weight-bold py-1">
          {{ $t("SETTING") }}
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-card-subtitle>
        <span class="text-subtitle-2">{{ $t("LANGUAGE") }}</span>
      </v-card-subtitle>
      <v-card-text class="py-1">
        <v-row dense>
          <v-col
            v-for="locale in availableLocales"
            :key="locale.code"
            cols="6"
            class="d-flex justify-center align-center"
          >
            <v-btn variant="flat" block @click="setLang(locale)">
              {{ locale.name }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-subtitle>
        <span class="text-subtitle-2">{{ $t("THEME") }}</span>
      </v-card-subtitle>
      <v-card-text class="py-1">
        <v-row dense>
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-btn variant="flat" block @click="toggleTheme">
              <v-icon left class="mr-4">{{
                theme.global.current.value.dark
                  ? "mdi-weather-sunny"
                  : "mdi-weather-night"
              }}</v-icon>
              <span>
                {{
                  $t("THEME_SWITCH", [
                    theme.global.current.value.dark
                      ? $t("THEME_LIGHT")
                      : $t("THEME_DARK"),
                  ])
                }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script setup>
import { useTheme, useDisplay } from "vuetify";
const colorMode = useColorMode();
const { locales, setLocale } = useI18n();
const snackbar = useSnackbar();
const { t } = useI18n();

// 响应式断点
const { smAndDown } = useDisplay();

const availableLocales = computed(() => {
  return locales.value;
});

// New global theme switcher
const theme = useTheme();
const cookie = useCookie("citizencat-theme", {
  maxAge: 60 * 60 * 24 * 30,
});
const toggleTheme = () => {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark";
  colorMode.preference = theme.global.name.value;
  colorMode.value = theme.global.name.value;
  cookie.value = theme.global.name.value;
};

// Locale switcher
const setLang = (locale) => {
  setLocale(locale.code);
  snackbar.add({
    type: "info",
    text: t("SETTING_LANGUAGE_SUCCESSFUL", [locale.name]),
  });
  reloadNuxtApp({ force: true, ttl: 1000 });
};
</script>
