<template>
  <v-navigation-drawer
    v-model="drawer"
    :rail="!smAndDown"
    :permanent="!smAndDown"
  >
    <v-list-item nav class="d-flex my-1 justify-center">
      <v-avatar rounded="0" size="36">
        <v-img src="/favicon.ico" />
      </v-avatar>
    </v-list-item>

    <v-divider :thickness="1" class="mx-1" />
    <v-list density="compact" nav>
      <v-list-item
        v-for="item in navItems"
        :key="item.text"
        :to="item.to"
        :prepend-icon="item.icon"
        :title="item.text"
        :exact="item.exact"
        link
      >
        <v-tooltip activator="parent" location="start" :disabled="smAndDown">
          {{ item.text }}
        </v-tooltip>
      </v-list-item>
    </v-list>
    <template #append>
      <clientOnly>
        <v-list density="compact">
          <site-navigation-left-drawer-setting />
          <site-navigation-left-drawer-user v-if="!smAndDown" />
        </v-list>
      </clientOnly>
    </template>
    <v-sheet v-if="smAndDown" height="100px" />
  </v-navigation-drawer>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { SiteNavigationDrawerStore } from "@/store/SiteNavigationDrawer";
const { t } = useI18n();

// 响应式断点
const { smAndDown } = useDisplay();

// Drawer状态
const store = SiteNavigationDrawerStore();
const drawerState = storeToRefs(store);
const drawer = drawerState.drawer;

const navItems = [
  {
    icon: "mdi-home-outline",
    text: t("HOME"),
    to: "/",
    exact: true,
  },
  {
    icon: "mdi-database-outline",
    text: t("UDB"),
    to: "/udb",
    exact: false,
  },
];
</script>
<style scoped>
.user-menu-icon {
  padding-right: 0 !important;
}
</style>
```
