<template>
  <v-menu
    :location="smAndDown ? 'top' : 'end'"
    offset="15"
    :width="smAndDown ? '100%' : '260px'"
  >
    <template #activator="{ props }">
      <v-icon v-if="smAndDown" size="24" v-bind="props">
        mdi-account-circle-outline
      </v-icon>
      <v-list-item
        v-else
        prepend-icon="mdi-account"
        value="account"
        :title="$t('USER_ACCOUNT')"
        v-bind="props"
      />
    </template>
    <v-card v-if="loggedIn" width="100%" elevation="5">
      <v-list class="py-0">
        <v-list-item class="d-flex align-center">
          <UsersInfoChip :user="user" />
        </v-list-item>
        <v-divider />
        <v-list-item exact to="/auth/setup">
          <v-icon left class="mr-4" color="grey-lighten-1">
            mdi-account-circle-outline
          </v-icon>
          <span class="text-subtitle-2">
            {{ $t("USER_SETTING") }}
          </span>
        </v-list-item>
        <v-list-item base-color="red" variant="flat" @click="userLogout()">
          <v-icon left class="mr-4"> mdi-logout </v-icon>
          <span class="text-subtitle-2">{{ $t("USER_LOGOUT") }}</span>
        </v-list-item>
      </v-list>
    </v-card>
    <v-card v-else width="100%" elevation="5">
      <v-list class="py-0">
        <v-list-item>
          <v-list-item-title class="text-body-1 font-weight-bold py-1">
            {{ $t("USER_NOT_LOGGEDIN") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          base-color="primary"
          variant="flat"
          @click="loginWithRedirect()"
        >
          <v-icon left class="mr-4"> mdi-login-variant </v-icon>
          <span class="text-subtitle-2">{{ $t("USER_LOGIN_REGISTER") }}</span>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useUserStore } from "@/store/SiteUser";

// 响应式断点
const { smAndDown } = useDisplay();

// 用户状态
const userStore = useUserStore();
const userData = storeToRefs(userStore);

const user = ref(userData.user);
const loggedIn = ref(userData.loggedIn);

const userLogout = () => {
  navigateTo("/auth/signout");
};

const loginWithRedirect = () => {
  const route = useRoute();
  const redirectPath = route.fullPath;
  const redirectCookie = useCookie("redirectPath");
  redirectCookie.value = redirectPath;
  navigateTo("/auth/signin");
};
</script>
